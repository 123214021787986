import { apiConfig } from '../../utils/apiConfig'
import axios from "axios";
import {addLogsAction} from "../reducers/appReducer";
import {
    activateIntervalAction, changeProcessingProgressAction,
    changeUploadProgressAction,
    disableIntervalAction,
    hideUploaderAction, setCurrentFileAnnotationsAction,
    setCurrentFolderAction, setFileUrlAction,
    setFolderFilesAction,
    setFoldersAction,
    setUploadEvent,
    showUploaderAction
} from "../reducers/folderReducer";
import authHeader from "./userAction";
import {
    activateValidIntervalAction,
    disablePublishIntervalAction,
    disableValidIntervalAction,
    hidePublishProgressAction,
    hideValidationProgressAction,
    setDatasetLogsAction,
    setPublishProgressAction,
    setSanitizersAction,
    setValidationEventAction,
    setValidationProgressAction,
    showValidationProgressAction
} from "../reducers/datasetReducer";


export const uploadFileAction = (files,dataset_id,sanitizers="") => {
    return async (dispatch) => {
        try {
            let room_id = localStorage.getItem("room_id")
            const formData = new FormData()
            files.forEach(file => formData.append('files', file))
            dispatch(showUploaderAction())
            let url = '/supplier/'+room_id+'/data/'+dataset_id
            if (sanitizers){
                url = url + '?sanitizers='+sanitizers
            }
            const response = await axios.post(url,
                formData ,
                {
                    headers: await authHeader(),
                    ...apiConfig,
                    onUploadProgress: ProgressEvent => {
                    const totalLength = ProgressEvent.lengthComputable ? ProgressEvent.total : ProgressEvent.target.getResponseHeader('content-length') || ProgressEvent.target.getResponseHeader('x-decompressed-content-length')

                    if (totalLength) {
                         let progress = Math.round((ProgressEvent.loaded * 100) / totalLength)
                        dispatch(changeUploadProgressAction(progress))
                    }
                }
            })
            response.data.logs.map(log=>(
                dispatch(addLogsAction(log))
            ))

            dispatch(setUploadEvent(response.data.event_id))
            dispatch(activateIntervalAction())

        } catch (e) {
            dispatch(addLogsAction({status:"error",time:Date.now(),text:e.message}))
        }
    }
}


export const importFileAction = (files,dataset_id,sanitizers="") => {
    return async (dispatch) => {
        try {
            let room_id = localStorage.getItem("room_id")
            const formData = new FormData()
            files.forEach(file => formData.append('files', file))
            dispatch(showUploaderAction())
            let url = '/supplier/'+room_id+'/data/import/'+dataset_id
            if (sanitizers){
                url = url + '?sanitizers='+sanitizers
            }
            const response = await axios.post(url,
                formData ,
                {
                    headers: await authHeader(),
                    ...apiConfig,
                    onUploadProgress: ProgressEvent => {
                        const totalLength = ProgressEvent.lengthComputable ? ProgressEvent.total : ProgressEvent.target.getResponseHeader('content-length') || ProgressEvent.target.getResponseHeader('x-decompressed-content-length')

                        if (totalLength) {
                            let progress = Math.round((ProgressEvent.loaded * 100) / totalLength)
                            dispatch(changeUploadProgressAction(progress))
                        }
                    }
                })
            response.data.logs.map(log=>(
                dispatch(addLogsAction(log))
            ))

            dispatch(setUploadEvent(response.data.event_id))
            dispatch(activateIntervalAction())

        } catch (e) {
            dispatch(addLogsAction({status:"error",time:Date.now(),text:e.message}))
        }
    }
}

export const getFoldersAction = (dataset_id,current_folder = "") => {
    return async (dispatch) => {
        try {
            let room_id = localStorage.getItem("room_id")
            const response = await axios.get('supplier/'+room_id+'/data/'+dataset_id, {
                headers: await authHeader(),
                ...apiConfig,
            })
            dispatch(setFoldersAction(response.data.folders))
            if(current_folder){
                let cur_fold = response.data.folders.filter(fold => fold.info.name === current_folder.info.name)
                dispatch(setCurrentFolderAction(cur_fold[0]))
            }else {
                dispatch(setCurrentFolderAction(response.data.folders[0]))

            }
        } catch (e) {
            dispatch(addLogsAction({status:"error",time:Date.now(),text:e.message}))
        }
    }
}

export const getEventsAction = (event_id,room = "") => {
    return async (dispatch) => {
        try {
            let room_id = localStorage.getItem("room_id")

            if (room){
                room_id = room
            }
            const response = await axios.get('supplier/'+room_id+'/events/'+event_id, {
                headers:await authHeader(),
                ...apiConfig,
            })
            let done = response.data.done
            let total = response.data.total
            if (response.data.type === "upload"){
                if (done === total){
                    dispatch(disableIntervalAction())
                    dispatch(changeProcessingProgressAction(100))
                    setTimeout(() => {
                        dispatch(hideUploaderAction())
                        dispatch(changeProcessingProgressAction(0))
                    }, 4000);
                }else {
                    dispatch(changeUploadProgressAction(100))
                    dispatch(changeProcessingProgressAction(Math.round(done/total*100)))
                }
            }else if (response.data.type === "publish"){
                if (done === total){
                    dispatch(disablePublishIntervalAction())
                    dispatch(setPublishProgressAction(100))
                    setTimeout(() => {
                        dispatch(hidePublishProgressAction())
                        dispatch(setPublishProgressAction(0))
                    }, 4000);
                }else{
                    dispatch(setPublishProgressAction(Math.round(done/total*100)))
                }
            }
            else {
                if (done === total){
                    dispatch(disableValidIntervalAction())
                    dispatch(setValidationProgressAction(100))
                    setTimeout(() => {
                        dispatch(hideValidationProgressAction())
                        dispatch(setValidationProgressAction(0))
                    }, 4000);
                }else{
                    dispatch(setValidationProgressAction(Math.round(done/total*100)))
                }

            }

        } catch (e) {
            dispatch(addLogsAction({status:"error",time:Date.now(),text:e.message}))
        }
    }
}

export const getFilesAction = (dataset_id,folder) => {
    return async (dispatch) => {
        try {
            let room_id = localStorage.getItem("room_id")
            const response = await axios.get('supplier/'+room_id+'/data/'+dataset_id+"/"+folder, {
                headers:await authHeader(),
                ...apiConfig,
            })
           dispatch(setFolderFilesAction(response.data.data))

        } catch (e) {
            dispatch(addLogsAction({status:"error",time:Date.now(),text:e.message}))
        }
    }
}



export const downloadFilesAction = (dataset_id,folder,file) => {
    return async (dispatch) => {
        try {
            let room_id = localStorage.getItem("room_id")
            const response = await axios.get('supplier/'+room_id+'/data/'+dataset_id+"/"+folder+"/"+file, {
                headers:await authHeader(),
                ...apiConfig,
                responseType: 'blob',
            })
            let file_url = []
            file_url.push(response.data)
            dispatch(setFileUrlAction(file_url))
        } catch (e) {
            dispatch(addLogsAction({status:"error",time:Date.now(),text:e.message}))
        }
    }
}

export const downloadPreviewFilesAction = (dataset_id,folder,files) => {
    return async (dispatch) => {
        try {
            let room_id = localStorage.getItem("room_id")
            let files_data = []
            for (const file of files.slice(0,3)){
                const response = await axios.get('supplier/'+room_id+'/data/'+dataset_id+"/"+folder+"/"+file.name, {
                    headers:await authHeader(),
                    ...apiConfig,
                    responseType: 'blob',
                })
                files_data.push(response.data)
            }
            dispatch(setFileUrlAction(files_data))


        } catch (e) {
            dispatch(addLogsAction({status:"error",time:Date.now(),text:e.message}))
        }
    }
}

export const getActiveEventsAction = (dataset_id) => {
    return async (dispatch) => {
        try {
            let room_id = localStorage.getItem("room_id")
            const response = await axios.get('supplier/'+room_id+'/events/upload/'+dataset_id, {
                headers:await authHeader(),
                ...apiConfig,
            })

            if(response.data.length > 0){
                dispatch(showUploaderAction())
                dispatch(activateIntervalAction())
                dispatch(setUploadEvent(response.data[0].id))
            }
        } catch (e) {
            dispatch(addLogsAction({status:"error",time:Date.now(),text:e.message}))
        }
    }
}


export const deleteFileAction = (dataset_id,folder,file) => {
    return async (dispatch) => {
        try {
            let room_id = localStorage.getItem("room_id")
            await axios.delete('supplier/'+room_id+'/data/'+dataset_id+"/"+folder+"/"+file, {
                headers:await authHeader(),
                ...apiConfig,
            })
            dispatch(getFilesAction(dataset_id,folder))
        } catch (e) {
            dispatch(addLogsAction({status:"error",time:Date.now(),text:e.message}))
        }
    }
}



export const addMarkupAction = (dataset_id,folder,file,annotations) => {
    return async (dispatch) => {
        let data = {annotations : annotations}
        try {
            let room_id = localStorage.getItem("room_id")
            await axios.post('supplier/'+room_id+'/annotations/'+dataset_id+"/"+folder+"/"+file,
                data,
                {
                    headers: await authHeader(),
                    ...apiConfig,
                })
        } catch (e) {
            dispatch(addLogsAction({status:"error",time:Date.now(),text:e.message}))
        }
    }
}



export const getFileMurkupsAction = (file) => {
    return async (dispatch) => {
        try {
            let room_id = localStorage.getItem("room_id")
            const response = await axios.get('supplier/'+room_id+'/annotations/'+file, {
                headers:await authHeader(),
                ...apiConfig,
            })
            dispatch(setCurrentFileAnnotationsAction(response.data.annotations))
        } catch (e) {
            dispatch(addLogsAction({status:"error",time:Date.now(),text:e.message}))
        }
    }
}

export const getSanitizersAction = (dataset_id) => {
    return async (dispatch) => {
        try {
            let room_id = localStorage.getItem("room_id")
            const response = await axios.get('supplier/'+room_id+'/sanitizers/'+dataset_id, {
                headers:await authHeader(),
                ...apiConfig,
            })
            dispatch(setSanitizersAction(response.data.sanitizers))
        } catch (e) {
            dispatch(addLogsAction({status:"error",time:Date.now(),text:e.message}))
        }
    }
}



export const getDatasetLogsAction = (dataset_id,room_id) => {
    return async (dispatch) => {
        try {
            const response = await axios.get('supplier/'+room_id+'/logs/'+dataset_id, {
                headers:await authHeader(),
                ...apiConfig,
            })
            dispatch(setDatasetLogsAction(response.data))
        } catch (e) {
            dispatch(addLogsAction({status:"error",time:Date.now(),text:e.message}))
        }
    }
}



export const startValidationAction = (dataset_id,room_id,validations) => {
    return async (dispatch) => {
        try {
            const response = await axios.post('supplier/'+room_id+'/validations/'+dataset_id,
                {validations : validations},
                {
                    headers: await authHeader(),
                    ...apiConfig,
                })
            dispatch(showValidationProgressAction())
            dispatch(activateValidIntervalAction())
            dispatch(setValidationEventAction(response.data.event_id))
        } catch (e) {
            dispatch(addLogsAction({status:"error",time:Date.now(),text:e.message}))
        }
    }
}

export const getActiveValidateEventsAction = (dataset_id,room_id) => {
    return async (dispatch) => {
        try {
            const response = await axios.get('supplier/'+room_id+'/events/validation/'+dataset_id, {
                headers:await authHeader(),
                ...apiConfig,
            })

            if(response.data.length > 0){
                dispatch(showValidationProgressAction())
                dispatch(activateValidIntervalAction())
                dispatch(setValidationEventAction(response.data[0].id))
            }
        } catch (e) {
            dispatch(addLogsAction({status:"error",time:Date.now(),text:e.message}))
        }
    }
}


export async function getMagicWandAction(dataset_id,folder,file,data) {
    const room_id = localStorage.getItem("room_id")
    let response =  await axios.post('supplier/'+room_id+'/annotations/magic_wand/'+dataset_id+"/"+folder+"/"+file,
        data,
        {
            headers: await authHeader(),
            ...apiConfig,
        })
    return response
}

export async function getSimplifyAnnoAction(dataset_id,folder,file,data) {
    const room_id = localStorage.getItem("room_id")
    let response =  await axios.post('supplier/'+room_id+'/annotations/simplify/'+dataset_id+"/"+folder+"/"+file,
        data,
        {
            headers: await authHeader(),
            ...apiConfig,
        })
    return response
}
