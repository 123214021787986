const SET_DATASETS = "SET_DATASETS"
const SET_CURRENT_DATASET = "SET_CURRENT_DATASET"
const SET_DS_ANNOTATIONS = "SET_DS_ANNOTATIONS"
const ENABLE_FOLDER_MODE = "ENABLE_FOLDER_MODE"
const DISABLE_FOLDER_MODE = "DISABLE_FOLDER_MODE"
const SET_SANITIZERS = "SET_SANITIZERS"
const SET_DATASET_LOGS = "SET_DATASET_LOGS"
const SET_VALIDATIONS = "SET_VALIDATIONS"
//validations
const ACTIVATE_VALIDATION_INTERVAL = "ACTIVATE_VALIDATION_INTERVAL"
const DISABLE_VALIDATION_INTERVAL = "DISABLE_VALIDATION_INTERVAL"
const SET_VALIDATION_EVENT = "SET_VALIDATION_EVENT"
const SET_VALIDATION_PROGRESS = "SET_VALIDATION_PROGRESS"
const SHOW_VALIDATION_PROGRESS = "SHOW_VALIDATION_PROGRESS"
const HIDE_VALIDATION_PROGRESS = "HIDE_VALIDATION_PROGRESS"
//publish
const ACTIVATE_PUBLISH_INTERVAL = "ACTIVATE_PUBLISH_INTERVAL"
const DISABLE_PUBLISH_INTERVAL = "DISABLE_PUBLISH_INTERVAL"
const SET_PUBLISH_EVENT = "SET_PUBLISH_EVENT"
const SET_PUBLISH_PROGRESS = "SET_PUBLISH_PROGRESS"
const SHOW_PUBLISH_PROGRESS = "SHOW_PUBLISH_PROGRESS"
const HIDE_PUBLISH_PROGRESS = "HIDE_PUBLISH_PROGRESS"

const SET_LOCAL_USERS = "SET_LOCAL_USERS"

let curDS = JSON.parse(localStorage.getItem("current_dataset"))
let folder_mode = localStorage.getItem("folder_mode") === "true"
const initialState = {
    datasets: [],
    currentDataset: curDS,
    files: [],
    folderMode:folder_mode,
    sanitizers:[],
    datasetLogs:[],
    validations:[],
    activateValidateInterval:false,
    validationEvent:"",
    validationProgress:0,
    validationProgressShow:false,
    activatePublishInterval:false,
    publishEvent:"",
    publishProgress:0,
    publishProgressShow:false,
    localUsers:[],
}

export const datasetReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_DATASETS:
            return { ...state, datasets: action.payload }
        case SET_CURRENT_DATASET:
            return { ...state, currentDataset: action.payload }
        case SET_DS_ANNOTATIONS:
            return { ...state, files: action.payload }
        case ENABLE_FOLDER_MODE:
            return { ...state, folderMode: true }
        case DISABLE_FOLDER_MODE:
            return { ...state, folderMode: false }
        case SET_SANITIZERS:
            return { ...state, sanitizers: action.payload }
        case SET_DATASET_LOGS:
            return { ...state, datasetLogs: action.payload }
        case SET_VALIDATIONS:
            return { ...state, validations: action.payload }
        case ACTIVATE_VALIDATION_INTERVAL:
            return { ...state, activateValidateInterval: true }
        case DISABLE_VALIDATION_INTERVAL:
            return { ...state, activateValidateInterval: false }
        case SET_VALIDATION_EVENT:
            return { ...state, validationEvent: action.payload }
        case SET_VALIDATION_PROGRESS:
            return { ...state, validationProgress: action.payload }
        case SHOW_VALIDATION_PROGRESS:
            return { ...state, validationProgressShow: true }
        case HIDE_VALIDATION_PROGRESS:
            return { ...state, validationProgressShow: false }
        case ACTIVATE_PUBLISH_INTERVAL:
            return { ...state, activatePublishInterval: true }
        case DISABLE_PUBLISH_INTERVAL:
            return { ...state, activatePublishInterval: false }
        case SET_PUBLISH_EVENT:
            return { ...state, publishEvent: action.payload }
        case SET_PUBLISH_PROGRESS:
            return { ...state, publishProgress: action.payload }
        case SHOW_PUBLISH_PROGRESS:
            return { ...state, publishProgressShow: true }
        case HIDE_PUBLISH_PROGRESS:
            return { ...state, publishProgressShow: false }
        case SET_LOCAL_USERS:
            return { ...state, localUsers: action.payload }
        default:
            return state
    }
}

export const setDatasetsAction = (datasets) => ({ type: SET_DATASETS, payload: datasets })
export const setCurrentDatasetAction = (dataset) => ({ type: SET_CURRENT_DATASET, payload: dataset })
export const setFilesAction = (files) => ({ type: SET_DS_ANNOTATIONS, payload: files })
export const enableFolderModeAction = () => ({ type: ENABLE_FOLDER_MODE})
export const disableFolderModeAction = () => ({ type: DISABLE_FOLDER_MODE})
export const setSanitizersAction = (sinit) => ({ type: SET_SANITIZERS,payload: sinit})
export const setDatasetLogsAction = (logs) => ({ type: SET_DATASET_LOGS,payload: logs})
export const setValidationsAction = (valid) => ({ type: SET_VALIDATIONS,payload: valid})
export const activateValidIntervalAction = () => ({ type: ACTIVATE_VALIDATION_INTERVAL})
export const disableValidIntervalAction = () => ({ type: DISABLE_VALIDATION_INTERVAL})
export const setValidationEventAction = (event) => ({ type: SET_VALIDATION_EVENT,payload: event})
export const setValidationProgressAction = (progress) => ({ type: SET_VALIDATION_PROGRESS,payload: progress})
export const showValidationProgressAction = () => ({ type: SHOW_VALIDATION_PROGRESS})
export const hideValidationProgressAction = () => ({ type: HIDE_VALIDATION_PROGRESS})

export const activatePublishIntervalAction = () => ({ type: ACTIVATE_PUBLISH_INTERVAL})
export const disablePublishIntervalAction = () => ({ type: DISABLE_PUBLISH_INTERVAL})
export const setPublishEventAction = (event) => ({ type: SET_PUBLISH_EVENT,payload: event})
export const setPublishProgressAction = (progress) => ({ type: SET_PUBLISH_PROGRESS,payload: progress})
export const showPublishProgressAction = () => ({ type: SHOW_PUBLISH_PROGRESS})
export const hidePublishProgressAction = () => ({ type: HIDE_PUBLISH_PROGRESS})

export const setLocalUsersAction = (users) => ({ type: SET_LOCAL_USERS,payload: users})
