import "./CreateDatasetAdministrator.scss";
import "./ClassesCreateComponent.scss"
import uniqid from 'uniqid';
import BackArrow from "../../../../assets/icons/back_arrow.svg"
import IconPlus from "../../../../assets/icons/icon_plus.svg"
import IconDelete from "../../../../assets/icons/icon_close.svg"
import IconDeleteRole from "../../../../assets/icons/icon_delete.svg"
import IconPersonal from "../../../../assets/icons/personal.svg"
import IconGroupe from "../../../../assets/icons/groupe.svg"
import {Link, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import UploadAnnotation from "./UploadAnnotation";
import SelectGosteh from "../../../SelectGosteh/SelectGosteh";
import InputGosteh from "../../../InputGostech/InputGosteh";
import {useDispatch, useSelector} from "react-redux";
import {
    createDatasetAction, deleteAnnotationAction, getAnnotationAction, getLocalUsersAction,
    updateDatasetAction,
    uploadAnnotationAction
} from "../../../../store/actions/datasetAction";
import {AUTH_METHOD} from "../../../../utils/constants";
import UserSelect from "../../../UserSelect/UserSelect";

function CreateDatasetAdministrator () {
    const navigate = useNavigate();
    const dispatch = useDispatch()

    const {currentDataset,files:datasetFiles,localUsers} = useSelector(store => store.dataset)
    const [edit,setEdit] = useState(false)
    const accept_formats = ["doc","docx","xls","xlsx","pdf","jpg","png","zip"]
    useEffect(()=>{
        dispatch(getLocalUsersAction())
        async function getAnnoFile(){
            const queryParams = new URLSearchParams(window.location.search);
            const action = queryParams.get('action');

            if (action === "edit"){
                await dispatch(getAnnotationAction(currentDataset._id))
            }
        }
        getAnnoFile()

    },[])

    useEffect(()=>{
        const queryParams = new URLSearchParams(window.location.search);
        const action = queryParams.get('action');

        if (action === "edit" && Object.keys(currentDataset).length > 0){
            setName(currentDataset.info.name)
            setOwner(currentDataset.info.owner)
            setDescription(currentDataset.info.description)
            setUsage(currentDataset.info.usage)
            setCounts(currentDataset.info.counts)
            setType(currentDataset.info.data_type === "photo" ? "Изображения" :
                currentDataset.info.data_type === "text" ? "Текст" : "Временные ряды")
            setScreenSize(currentDataset.info.screen_size)
            setRoles(currentDataset.info.roles.map(r => {
                let new_role = {}
                new_role.role = r.role
                new_role.role_type = r.ogrn ? "ogrn" : "user"
                new_role.value = r.ogrn ? r.ogrn : localUsers.filter(user=>user._id === r.user_oid)[0].fullname
                new_role.id = uniqid()+Date.now()
                return new_role
            }))
            setClasses(currentDataset.info.categories)
            setFiles(datasetFiles.map(file=>{
                let new_file = {}
                new_file.name = file
                new_file.type = "old"
                return new_file
            }))

            setEdit(true)
        }
    },[currentDataset,datasetFiles,localUsers])


    const [name,setName] = useState("")
    const [validName,setValidName] = useState(true)

    const [owner,setOwner] = useState("")
    const [validOwner,setValidOwner] = useState(true)

    const [description,setDescription] = useState("")
    const [validDescription,setValidDescription] = useState(true)

    const [usage,setUsage] = useState("")
    const [validUsage,setValidUsage] = useState(true)

    const [counts,setCounts] = useState("")
    const [validCounts,setValidCounts] = useState(true)

    const [type,setType] = useState("")
    const [screenSize,setScreenSize] = useState("")

    const [roles,setRoles] = useState([{role : "",value : "", role_type:"ogrn",id: uniqid()+Date.now()}])
    const [files,setFiles] = useState([])

    const [validFiles,setValidFiles] = useState(true)

    const [contexMenuClass,setContexMenuClass] = useState("")

    const [showClassInput,setShowClassInput] = useState(false)

    const options_type = ["Изображения","Временные ряды","Текст"]
    const def_opt = "Не выбрано"

    const sizes = ["800x600","848x480","960x540","1024x600","1024x768","1152x864","1200x600","1280x720","1280x768",
                    "1280x1024","1280x720","1280x960","1280x1024","1920x1080","1920x1200","2048x1536","2592x1520",
                    "2560x1960","3072x2048","3840x2160","7680x4320"]


    const [className,setClassName] = useState("")
    const [color,setColor] = useState("")

    const [classes,setClasses] = useState([])
    const [validClass,setValidClass] = useState(true)

    const [checkRoles,setCheckRoles] = useState(true)

    let checks = (name.trim() && validName && owner && validOwner && description.trim() && validDescription
        && usage.trim() && validUsage && type && checkRoles
        && classes.length > 0 && counts && validCounts && files.length > 0)

    if (type==="Изображения"){
        checks = checks && screenSize
    }

    const colors = ['#FF0000','#FF6B00', '#FFA800','#FFE500','#1E6E39','#00FF0A','#00CFB6','#00FFF0',
        '#238885','#00A3FF','#0029FF','#7000FF','#8D00CF','#FF00F5','#8B3A00','#8E0026']


    const colors_opacity = ['rgb(255, 0, 0, 0.3)','rgb(255, 107, 0, 0.3)', 'rgb(255, 168, 0, 0.3)','rgb(255, 229, 0, 0.3)','rgb(30, 110, 57, 0.3)','rgb(0, 255, 10, 0.3)','rgb(0, 207, 182, 0.3)','rgb(0, 255, 240, 0.3)',
        'rgb(35, 136, 133, 0.3)','rgb(0, 163, 255, 0.3)','rgb(0, 41, 255, 0.3)','rgb(112, 0, 255, 0.3)','rgb(141, 0, 207, 0.3)','rgb(255, 0, 245, 0.3)','rgb(139, 58, 0, 0.3)','rgb(142, 0, 38, 0.3)']

    const [showRightMenu, setShowRightMenu] = useState(false);
    const [position, setPosition] = useState({ x: 0, y: 0 });

    function handleChangeClassName(e){
        if (e.target.value.length <= 16){
            setClassName(e.target.value)
        }
    }

    function handleChangeName(e){
        setValidName(true)
        setName(e.target.value)
        if (e.target.value.length > 255){
            setValidName(false)
        }
    }

    function handleChangeOwner(e){
        setValidOwner(true)
        let numberRegex = /^\d+$/;
        if((numberRegex.test(e.target.value) && e.target.value.length <= 15) || e.target.value.length === 0){
            setOwner(e.target.value)
        }
    }

    function handleCheckOwner(e){
        if (e.target.value.length < 13 || e.target.value.length === 14){
            setValidOwner(false)
        }else {
            setValidOwner(true)
        }

    }

    function handleChangeCounts(e){
        setValidCounts(true)
        setCounts(e.target.value)
        let numberRegex = /^\d+$/;
        if(!numberRegex.test(e.target.value)){
            setValidCounts(false)
        }
    }

    function handleChangeDescription(e){
        setValidDescription(true)
        setDescription(e.target.value)
        if(e.target.value.length > 2000){
            setValidDescription(false)
        }
    }

    function handleChangeUsage(e){
        setValidUsage(true)
        setUsage(e.target.value)
        if(e.target.value.length > 2000){
            setValidUsage(false)
        }
    }

    function handleRoleChange(option,index){
        let new_roles = roles
        new_roles[index].role = option
        setRoles(new_roles)
    }

    function handleOgrnChange(val,index){
        setCheckRoles(false)
        let new_roles = roles
        new_roles[index].value = val
        setRoles(new_roles)
        let check_roles = roles.filter(role => role.role === "").length === 0 &&
            roles.filter(role => (role.value.length < 13 || role.value.length === 14) && role.role_type === "ogrn").length === 0 &&
            roles.filter(role => role.role_type === "user" && localUsers.filter(user => user.fullname === role.value).length === 0).length === 0 &&
            roles.filter(role => role.role && role.value).length > 0
        setCheckRoles(check_roles)
    }

    function handleAddRole(){
        if (roles.length < 20){
            setRoles([...roles,{role : "",value : "",role_type : "ogrn", id:uniqid()+Date.now()}])
        }
    }

    function handleDeleteRole(rol){
        setCheckRoles(false)
        let new_roles = roles.filter(r => r.id !== rol.id)
        setRoles(new_roles)
        let check_roles =  new_roles.filter(role => role.role && role.value).length > 0
        setCheckRoles(check_roles)
    }

    function handleRoleType(role_type,index){
        if (AUTH_METHOD === "local"){
            let new_roles = JSON.parse(JSON.stringify(roles))
            new_roles[index].value = ""
            new_roles[index].role_type = role_type === "ogrn" ? "user" : "ogrn"
            setRoles(new_roles)
        }
    }

    function handleBtnAddClass(){
        if (classes.length < 16){
            setShowClassInput(true)
        }
    }

    function clickListener(e){
        setValidFiles(true)
        setValidClass(true)
        if(!e.target.classList.contains("contex_menu_check")){
            setShowRightMenu(false)
        }

        if (!e.target.classList.contains("color_check")){
            if (className.trim()) {
                let used_colors = classes.map(clas => (clas.color))
                let free_color = colors
                free_color = free_color.filter( ( el ) => !used_colors.includes( el ) );

                if (classes.filter(cl => cl.name === className.trim()).length === 0){
                    if (color){
                        setClasses([...classes,{name:className,color:color}])
                    }else {
                        if(free_color.length > 0){
                            setClasses([...classes,{name:className,color:free_color[0]}])
                        }
                    }
                }else {
                    setValidClass(false)
                }
            }
            setClassName("")
            setColor("")
            setShowClassInput(false)
        }

    }

    function handleContexMenu(e,cl){
        e.preventDefault();
        setPosition({ x: e.pageX, y: e.pageY });
        setShowRightMenu(true);
        setContexMenuClass(cl)
    }

    function handleDeleteClass(){
        setClasses(classes.filter(cl => cl.name!==contexMenuClass.name || cl.color!==contexMenuClass.color))
        setShowRightMenu(false);
    }

    function handleCreateDataset(){
        if (checks){
            console.log(roles)
            let dataset = {
                name:name.trim(),
                owner:owner,
                description:description.trim(),
                usage:usage.trim(),
                data_type:type === "Изображения" ? "photo" : type === "Текст" ? "text" : "time_series",
                roles:roles.map(role => {
                    let new_role = {}
                    new_role.role = role.role
                    if (role.role_type === "ogrn"){
                        new_role.ogrn = role.value
                    }else {
                        new_role.user_oid = localUsers.filter(user => user.fullname === role.value)[0]._id
                    }
                    return new_role
                }),
                counts:counts,
                screen_size:screenSize
            }
            if (edit){
                let class_new = classes.map(cl => {
                    if(!cl.hasOwnProperty("id")){
                        cl.id = uniqid()+Date.now()
                    }
                    return cl
                })
                dataset.categories = class_new
                dispatch(updateDatasetAction(dataset,currentDataset._id))

                let old_files = datasetFiles
                let new_files = files.map(file => file.name)
                let delete_files = old_files.filter(old => ! new_files.includes(old))

                delete_files.forEach(file => dispatch(deleteAnnotationAction(currentDataset._id,file)))

                files.filter(file => file.type !== "old").forEach(file => dispatch(uploadAnnotationAction(file,currentDataset._id)))
                navigate("/my_room_admin");
            }else {
                dataset.categories = classes.map(cl=>{
                    cl.id = uniqid()+Date.now()
                    return cl
                })
                dispatch(createDatasetAction(dataset,files))
                navigate("/my_room_admin");
            }

        }
    }



    return (
        <div className="content" onClick={(e)=>clickListener(e)} id="main_conteiner">
            <div className="ds_create">
                <Link to="/my_room_admin" className="ds_create_back">
                    <img src={BackArrow} alt="Back arrow"/>
                    <p className="gt_ps">Назад к моему пространству</p>
                </Link>
                <p className="gt_h3">Администратор: {edit ? "изменение" : "создание"} набора данных</p>
                <form className="ds_create_form" onSubmit={(e) => e.preventDefault()}>
                    <div className="input_group">
                        <label className={validName ? "gt_ih" : "gt_ih gt_gr"} htmlFor="name">
                            Наименование набора данных<span className="red">*</span>
                        </label>
                        <input id="name" className="ds_create_form_input gt_pt shadow" value={name} onChange={handleChangeName}/>
                        {!validName && <p className="gt_ps gt_gr">Неверный формат: Максимум 255 знаков.</p>}
                    </div>
                    <div className="input_group">
                        <label className={validOwner ? "gt_ih" : "gt_ih gt_gr"} htmlFor="owner">
                            Владелец набора данных<span className="red">*</span>
                        </label>
                        <input id="owner" className="ds_create_form_input gt_pt shadow" value={owner} onChange={handleChangeOwner} onBlur={(e)=>handleCheckOwner(e)}/>
                        {!validOwner && <p className="gt_ps gt_gr">Неверный формат: 13 или 15 символов (только числа).</p>}
                    </div>

                    <div className="input_group">
                        <label className={validDescription ? "gt_ih" : "gt_ih gt_gr"} htmlFor="description">
                            Описание<span className="red">*</span>
                        </label>
                        <textarea id="description" rows={3} className="ds_create_input gt_pt shadow" value={description} onChange={handleChangeDescription}/>
                        {!validDescription && <p className="gt_ps gt_gr">Неверный формат: Максимум 2000 знаков.</p>}
                    </div>

                    <div className="input_group">
                        <label className={validUsage ? "gt_ih" : "gt_ih gt_gr"} htmlFor="usage">
                            Использование<span className="red">*</span>
                        </label>
                        <textarea id="usage" rows={3} className="gt_pt shadow" value={usage} onChange={handleChangeUsage}/>
                        {!validUsage && <p className="gt_ps gt_gr">Неверный формат: Максимум 2000 знаков.</p>}
                    </div>

                    <div className="input_group">
                        <p className="gt_ih"> Аннотация<span className="red">*</span></p>
                        <UploadAnnotation files={files} setFiles={setFiles} setValidFiles={setValidFiles} edit={edit} accept_formats={accept_formats} max_count={10}/>
                        {!validFiles && <p className="gt_ps gt_gr">Недопустимое разрешение файлов</p>}
                    </div>

                    <div className="input_group">
                        <label className="gt_ih">
                            Тип данных<span className="red">*</span>
                        </label>
                        <SelectGosteh value={type} options={options_type} default_option={def_opt} selectChange={setType}/>
                    </div>
                    {type === "Изображения" ?
                        <div className="input_group">
                            <label className="gt_ih">
                                Разрешение изображения (px)<span className="red">*</span>
                            </label>
                            <SelectGosteh value={screenSize} options={sizes} default_option={def_opt} selectChange={setScreenSize}/>
                        </div> : ""}

                    <div className="input_group">
                        <label className="gt_ih">
                            Роли<span className="red">*</span>
                        </label>
                        {
                            roles.map((role,i) => (
                                <div className="ds_create_form_roles" key={role.role+role.value +i}>
                                    <div className="role">
                                        <SelectGosteh options={['Поставщик','Разметчик']} value={role.role} default_option={'Не выбрано'} selectChange={handleRoleChange} index={i}/>
                                    </div>
                                    <button type="button" className="role_persona_icon shadow" onClick={()=>handleRoleType(role.role_type,i)}>
                                        <img alt="icon" src={role.role_type === "ogrn" ? IconGroupe : IconPersonal}/>
                                    </button>
                                    {
                                        role.role_type === "ogrn" ?
                                            <div className="ogrn">
                                                <InputGosteh placeholder="Введите ОГРН" value={role.value}
                                                            inputChange={handleOgrnChange} validate="ogrn" index={i}/>
                                            </div> :
                                            <div className="ogrn">
                                                <UserSelect users={localUsers} inputChange={handleOgrnChange} index={i} val={role.value}/>
                                            </div>
                                    }
                                    <button type="button" className="delete_role_btn shadow" onClick={()=>handleDeleteRole(role)}><img src={IconDeleteRole} alt="delete"/></button>

                                </div>
                            ))
                        }
                        <button type="button" className= "add_role" onClick={handleAddRole}><img src={IconPlus} alt="icon plus"/><p className="gt_pt gt_gb">Добавить роль</p></button>
                    </div>


                    <div className="input_group">
                        <label className="gt_ih">
                            Классы<span className="red">*</span>
                        </label>
                        <div className="class_block">
                            <div className="class_list">
                                <button type="button" className="add_class_btn color_check shadow" onClick={handleBtnAddClass}><img className="color_check" src={IconPlus} alt="icon plus"/></button>

                                {classes.map((cl,i) => (

                                    <div key={cl.name+cl.color} className="class_element">

                                        <div className="main_color " style={{backgroundColor:cl.color}}/>
                                        <div key={cl.name+cl.color} className="gt_pt op_color contex_menu_check" style={{backgroundColor: colors_opacity[colors.indexOf(cl.color)]}} onClick={(e)=>handleContexMenu(e,cl)}  onContextMenu={(e)=>handleContexMenu(e,cl)}>
                                            {cl.name}
                                        </div>
                                        {showRightMenu &&
                                            <div className="right-menu contex_menu_check " style={{ top: position.y, left: position.x , boxShadow: "2px 2px 8px #EDF2FE"}}>
                                                <button type="button" className="contex_menu_check right-menu-item" onClick={handleDeleteClass}>
                                                    <img className="contex_menu_check" src={IconDelete} alt="delete"/><p className="gt_pt contex_menu_check">Удалить</p>
                                                </button>
                                            </div>
                                        }
                                    </div>
                                    ))}
                                {showClassInput &&
                                    <div className="input_class">
                                        <input placeholder="Введите имя класса" className="gt_input gt_pt color_check shadow" value={className} onChange={(e)=>handleChangeClassName(e)}/>
                                        <div className="color_picker color_check">
                                            <div className="color_picker_container color_check">
                                                {colors.map(col=>(
                                                    <button type="button" key={col} className={`color_circle color_check ${color === col ? 'active_color' : ''}`} style={{ backgroundColor: col }} onClick={()=>setColor(col)}/>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        {!validClass && <p className="gt_ps gt_gr">Нельзя создавать классы с одинаковыми названиями</p>}
                    </div>

                    <div className="input_group">
                        <label className={validCounts ? "gt_ih" : "gt_ih gt_gr"} htmlFor="counts">
                            Плановый объем (количество элементов)<span className="red">*</span>
                        </label>
                        <input id="counts" className="ds_create_form_input gt_pt shadow" value={counts} onChange={handleChangeCounts}/>
                        {!validCounts && <p className="gt_ps gt_gr">Неверный формат: Только числа.</p>}
                    </div>

                    <button className="submit_listen"/>
                    <div className="submit_block">
                        <p className="gt_ps gt_th">Пожалуйста, заполните все обязательные поля, чтобы создать набор данных.</p>
                        <button disabled={!checks} className="gt_btn_primary gt_pt" type="button" onClick={handleCreateDataset}>{edit ? "Изменить набор данных" : "Создать набор данных"}</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default CreateDatasetAdministrator;
