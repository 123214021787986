import "./UserSelect.scss";
import {useEffect, useState} from "react";


function UserSelect ({users,inputChange,index,val = ""}){
    const [showOpt,setShowOpt] = useState(false);
    const [value, setValue] = useState(val);
    const [valid, setValid] = useState(false);
    useEffect(() => {
        function handleClick(e){
            if(!e.target.classList.contains("user_select_input")){
                setShowOpt(false)
                setValid(false)
                if(users.filter(user => user.fullname === value).length === 0){
                    setValid(true)
                }else {
                    setValid(false)
                }
            }
        }
        window.addEventListener('click', handleClick);
        return () => {
            window.removeEventListener('click', handleClick);
        };
    }, [showOpt,value,users]);



    function handleChange(e){
        setValue(e.target.value)
        inputChange(e.target.value,index)
    }

    function handleSelect(opt){
        setValue(opt.fullname)
        inputChange(opt.fullname,index)
    }

    return (
        < >
            <input className="gt_input gt_pt shadow user_select_input" value={value} onClick={()=>setShowOpt(true)} onChange={handleChange} />
            {valid && <p className="gt_ps gt_gr">Пользователь с таким именем не существует</p>}
            <div  className="user_select_block shadow">
                {
                    showOpt && users.filter(opt => opt.fullname.toLowerCase().includes(value.toLowerCase())).map((opt, i) => (
                    <div key={opt.fullname + i}>
                        <div className="user_select option_item">
                            <button type="button" className="user_select_option" onClick={()=>handleSelect(opt)}>
                                <p className="gt_pt">{opt.fullname}</p>
                            </button>
                        </div>
                    </div>
                    ))
                }
            </div>
        </>

    )
}

export default UserSelect;
