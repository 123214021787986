import { useEffect, useRef, useState } from 'react';
import { Annotorious } from '@recogito/annotorious';
import {COLORS} from "../../utils/color_for_classes"
import "./Annotation.scss"
import "../MyRoom/Administrator/MyRoom/AdminDatasetsComponent/AdminDatasetsComponent.scss"
import '@recogito/annotorious/dist/annotorious.min.css';
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import IconBack from "../../assets/icons/icon-arrow-left.svg";
import IconSearch from "../../assets/icons/icon_search.svg";
import IconWait from "../../assets/icons/wait.svg";
import IconFile from "../../assets/icons/file_icon.svg";
import IconFileMark from "../../assets/icons/file_marked.svg";
import IconEdit from "../../assets/icons/edit.svg";
import IconEditDisable from "../../assets/icons/edit_disable.svg";
import {
    setCurrentFileAction,
    setCurrentFileAnnotationsAction,
} from "../../store/reducers/folderReducer";
import {
    addMarkupAction,
    downloadFilesAction,
    getFileMurkupsAction,
    getFilesAction, getMagicWandAction, getSimplifyAnnoAction,
} from "../../store/actions/FolderAction";
import {showDeleteModalAction} from "../../store/reducers/appReducer";
import DeleteModal from "../DeleteModal/DeleteModal";
import {checkIsPointOnEdge} from "./helper";

function Annotation() {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const {showDeleteModal} = useSelector(store=>store.app)
    const {currentFolder,files,fileUrl,currentFile,currentFileAnnotations} = useSelector(store=>store.folder)
    const {currentDataset} = useSelector(store=>store.dataset)
    const [search,setSearch] = useState("")
    const [activeCategory,setActiveCategory] = useState("")
    const [reload,setReload] = useState(false)
    const [meta,setMeta] = useState("")
    const [editMeta,setEditMeta] = useState(false)

    const [accuracy,setAccuracy] = useState("25")
    const [param,setParam] = useState("15")
    // Ref to the image DOM element
    const imgEl = useRef();
    // The current Annotorious instance
    const [ anno, setAnno ] = useState();
    const [shiftKey, setShiftKey] = useState(false);
    const [altKey, setAltKey] = useState(false);
    // Current drawing tool name
    const [ tool, setTool ] = useState('');

    const [activeEdge, setActiveEdge] = useState(false);

    //функция для перезакгрузки компонента в случии неправильной разметки
    function reloadAnnoComponent(){
        if (anno){
            let anno_list = JSON.parse(localStorage.getItem("anno_list"))
            let new_anno = anno.getAnnotations().length
            if (anno_list){
                if(anno_list.length !== new_anno){
                    setReload(!reload)
                }
            }else{
                if (new_anno > 0){
                    setReload(!reload)
                }
            }
        }
    }

    //добавление в список существующих разметок для функции перезагрузки (для отслеживания багов виджета)
    function addToAnnoList(id){
        let anno_list = JSON.parse(localStorage.getItem("anno_list"))
        if (anno_list){
            anno_list.push(id)
        }else {
            anno_list = [id]
        }
        localStorage.setItem("anno_list",JSON.stringify(anno_list))
    }

    //удаление из списка разметок для функции перезагрузки (для отслеживания багов виджета)
    function deleteFromAnnoList(id){
        let anno_list = JSON.parse(localStorage.getItem("anno_list"))
        if (anno_list){
            anno_list = anno_list.filter(an => an !== id)
        }
        localStorage.setItem("anno_list",JSON.stringify(anno_list))
    }

    function timeout(delay) {
        return new Promise( res => setTimeout(res, delay) );
    }

    let formatter = function(annotation) {
        if (currentDataset){
            if(annotation.bodies.filter(prop => prop.purpose === "category").length > 0){
                let category = annotation.bodies.filter(prop => prop.purpose === "category")[0].value
                if(currentDataset.info.categories.filter(cat => cat.id === category).length > 0){
                    let color = currentDataset.info.categories.filter(cat => cat.id === category)[0].color
                    let opacity = COLORS.filter(col => col.color === color)[0].opacity

                    let style = `stroke-width:2; stroke: ${color}; fill: ${opacity};`

                    return {
                        'style': style
                    }
                }

            }
        }

    }


    useEffect(()=>{
        if (currentFileAnnotations && anno){
            if (imgEl.current.id === "main"){
                let annotate = [];
                anno.clearAnnotations();
                if (currentFileAnnotations.length > 0){
                    if (currentFile === currentFileAnnotations[0].filename){
                        currentFileAnnotations.forEach(an => {
                            annotate.push({
                                "@context": "http://www.w3.org/ns/anno.jsonld",
                                "id": an._id,
                                "type": "Annotation",
                                "body": [ {
                                    "type": "TextualBody",
                                    "purpose": "category",
                                    "value": an.category.id
                                },
                                    {
                                        "type": "TextualBody",
                                        "purpose": "meta",
                                        "value": an.meta
                                    }],
                                "target": {
                                    "selector": {
                                        "type": an.type,
                                        "conformsTo": "http://www.w3.org/TR/media-frags/",
                                        "value": an.value
                                    }
                                }
                            })
                        })
                    }
                }

                if (annotate.length > 0){
                    let id = annotate.map(an=>an.id)
                    localStorage.setItem("anno_list",JSON.stringify(id))
                    anno.setAnnotations(annotate);
                }
            }
        }
    },[currentFileAnnotations,anno])



    useEffect( ()=>{
        //console.log("cahnge file")
        async function getData(){
            localStorage.removeItem("history")
            if(currentFolder && currentFile && anno){
                localStorage.removeItem("category")
                localStorage.removeItem("drawing_tool")
                setTool('rect')
                anno.setDrawingTool('rect')
                setActiveCategory("")
                setMeta("")
                localStorage.removeItem("anno_meta")
                localStorage.removeItem("anno_list")

                localStorage.removeItem("current_anno")
                localStorage.removeItem("selected_anno")
                //await dispatch(setFileUrlAction([]))
                anno.clearAnnotations();
                await dispatch(downloadFilesAction(currentDataset._id, currentFolder.info.name, currentFile))
                await dispatch(getFileMurkupsAction(currentFile))
            }
        }
        getData()
    },[currentFile])

    useEffect(()=>{

        if (files.length > 0){
            dispatch(setCurrentFileAction(files[0].name))
            dispatch(getFileMurkupsAction(files[0].name))
        }
        localStorage.removeItem("anno_list")

        if (files.length === 0 ){
            navigate("/my_room_admin");
        }

    },[])

    useEffect(() => {

        const handleDown = (e) => {
            //перехват клавиши alt (option / mac ) для удадения точки с полигона
            setAltKey(e.keyCode===18);
            setShiftKey(e.shiftKey);
             if (e.keyCode===18){
                 let elem = document.querySelectorAll(".a9s-annotation.editable.selected")
                 if(elem.length > 0){
                     elem[0].classList.add("delete_point")
                 }
             }
            //перехват ctrl+z для отмены действий
            if (e.keyCode === 90 && e.ctrlKey){
                const history = JSON.parse(localStorage.getItem("history"))
                if (history){
                    if (history.action === "add"){
                        anno.removeAnnotation(history.annotation)
                        deleteFromAnnoList(history.annotation.id)
                    } else if (history.action === "concat" || history.action === "rounding"){
                        anno.removeAnnotation(history.annotation)
                        anno.addAnnotation(history.prev)
                        deleteFromAnnoList(history.annotation.id)
                        addToAnnoList(history.prev.id)
                    }
                }
                localStorage.removeItem("history")
            }
            //перехват enter для Огрубления полигона в магической палолке
            else if (e.key === 'Enter') {
                let selected = anno.getSelected()

                if (selected && param){
                    let prev = JSON.parse(JSON.stringify(selected))

                    let markup ={}
                    markup._id = selected.id
                    markup.type = selected.target.selector.type
                    markup.value = selected.target.selector.value

                    if (selected.body.filter(prop => prop.purpose === 'category').length > 0){
                        markup.category = {id : selected.body.filter(prop => prop.purpose === 'category')[0].value}
                    }
                    if (selected.body.filter(prop => prop.purpose === 'meta').length > 0){
                        markup.meta = selected.body.filter(prop => prop.purpose === 'meta')[0].value
                    }

                    let data = {
                        "annotation" : markup,
                        "rounding":  Number(param)
                    }

                    anno.removeAnnotation(selected)

                    deleteFromAnnoList(selected.id)

                    const fetchData = async () => {
                        let resp = await getSimplifyAnnoAction(currentDataset._id,currentFolder.info.name,currentFile,data)
                        if (resp){
                            const wand = resp.data
                            selected.id = wand._id
                            selected.target.selection = "<svg><polygon points=\""+wand.value+"\"></polygon></svg>"
                            selected.target.selector.value = "<svg><polygon points=\""+wand.value+"\"></polygon></svg>"
                            addToAnnoList(wand.id)
                            anno.addAnnotation(selected)
                            anno.selectAnnotation(selected);

                            const history = {
                                action : "rounding",
                                annotation : selected,
                                prev : prev
                            }

                            localStorage.setItem("history", JSON.stringify(history))
                        }
                    }
                    fetchData();


                }
            } else if (e.key === 'Shift'){
                localStorage.setItem("shiftPress","true")
            } else if (e.keyCode === 8){//удаление разметки при нажатии на backspase
                if(anno.getSelected()){
                    let selected = anno.getSelected()
                    anno.removeAnnotation(selected)
                    deleteFromAnnoList(selected.id)
                }
            }


        };
        //событие отжима клавиш
        const handleUp = (e) => {
            setShiftKey(false);
            localStorage.setItem("shiftPress","false")
            setAltKey(false);
            console.log("up")
            let elem = document.querySelectorAll(".a9s-annotation.editable.selected.delete_point")
            if(elem.length > 0){
                elem[0].classList.remove("delete_point")
            }
        };

        const handleClick = (e) => {
            //const sel_anno = anno.getSelected() ? anno.getSelected() : JSON.parse(localStorage.getItem("selected_anno"))
            const sel_anno = JSON.parse(localStorage.getItem("current_anno"))

            //проверка клика по точке полигона
            if (e.target.tagName === "circle"){
                const x = e.target.cx.baseVal.value
                const y = e.target.cy.baseVal.value

                //удаление точки с полигона
                if (altKey && anno.getSelected()){
                    if(anno.getSelected().target.selector.type === "SvgSelector"){
                        let selected = sel_anno
                        const poligon = selected.target.selector.value
                        let poits = poligon.substring(22,poligon.indexOf("\"",22)).split(' ')
                        if (poits.length > 3){
                            if (poits.indexOf(x+","+y) !== -1){
                                async function del_point(){
                                    poits.splice(poits.indexOf(x+","+y),1)
                                    selected.target.selector.value = poligon.substring(0,22)+poits.join(" ")+"\"></polygon></svg>"
                                    anno.removeAnnotation(selected.id)
                                    await timeout(10);
                                    anno.addAnnotation(selected)
                                    await timeout(10);
                                    anno.selectAnnotation(selected.id)
                                    localStorage.setItem("current_anno", JSON.stringify(selected))
                                }
                                del_point()
                            }
                        }
                    }
                }
            }
           //клик по грани полигона для добавления точки
            else if (sel_anno && activeEdge){
                async function add_point(){
                    let selected = sel_anno
                    //let new_point = Math.round((activeEdge[0][0] + activeEdge[1][0])/2) + ","+Math.round((activeEdge[0][1] + activeEdge[1][1])/2)
                    let new_point = Math.round(activeEdge[2][0])+","+Math.round(activeEdge[2][1])
                    const str_start = activeEdge[0][0]+","+activeEdge[0][1]
                    const start = selected.target.selector.value.indexOf(str_start) + str_start.length
                    const end = selected.target.selector.value.indexOf(activeEdge[1][0]+","+activeEdge[1][1])
                    if (end === 22){
                        selected.target.selector.value = selected.target.selector.value.slice(0, 22) +new_point+" " + selected.target.selector.value.slice(22)
                    }else {
                        selected.target.selector.value = selected.target.selector.value.slice(0, start) +" "+new_point+" " + selected.target.selector.value.slice(end)
                    }

                    anno.saveSelected()
                    await timeout(10);
                    anno.removeAnnotation(selected.id)
                    await timeout(10);
                    anno.addAnnotation(selected)
                    await timeout(10);
                    anno.selectAnnotation(selected.id)

                    localStorage.setItem("current_anno", JSON.stringify(selected))

                }
                add_point()
            }
            //сохранение разметки при двойном клике
            else if (e.detail === 2) {
                anno.saveSelected()
            }

            localStorage.removeItem("selected_anno")
        }

        //Изменение курсора при наведение на ребро активного полигона (для добавления новой точки в полигон)
        const handleMouseMove = (e) => {
            let elem = document.getElementById('main')
            let add_icon = false
            let current = JSON.parse(localStorage.getItem("current_anno"))
            if(elem && anno){
                if (current){
                    if(current.target.selector.type === "SvgSelector"){
                        let dim = elem.getBoundingClientRect();
                        let x = e.clientX - dim.left;
                        let y = e.clientY - dim.top;
                        const poligon = current.target.selector.value
                        let poits = poligon.substring(22,poligon.indexOf("\"",22))
                        poits = poits.split(' ').map(point => point.split(",").map(p =>  Number(p)))

                        for (let i = 0; i < poits.length; i++) {
                            let edge = []
                            let check = false
                            if (i < poits.length - 1 ){
                                edge = [poits[i],poits[i+1]];
                                check = checkIsPointOnEdge(x, y, edge)
                            }else {
                                edge = [poits[i],poits[0]];
                                check = checkIsPointOnEdge(x, y, edge)
                            }
                            if (check){
                                add_icon = check
                                add_icon.push([x,y])
                            }
                        }
                    }
                }

                if (add_icon){
                    let elem = document.querySelectorAll(".a9s-annotation.editable .a9s-inner")
                    let elem2 = document.querySelectorAll(".a9s-annotationlayer")
                    if(elem.length > 0 && elem2.length > 0){
                        elem[0].classList.add("custom_add_cursor")
                        elem2[0].classList.add("custom_add_cursor")
                    }
                }else {
                    let elem = document.querySelectorAll(".a9s-annotation.editable .a9s-inner")
                    let elem2 = document.querySelectorAll(".a9s-annotationlayer")
                    if(elem.length > 0){
                        elem[0].classList.remove("custom_add_cursor")
                    }
                    if( elem2.length > 0){
                        elem2[0].classList.remove("custom_add_cursor")
                    }

                }

                setActiveEdge(add_icon)
            }

        }

        window.addEventListener('click', handleClick);
        window.addEventListener('keydown', handleDown);
        window.addEventListener('keyup', handleUp);
        document.addEventListener('mousemove', handleMouseMove);
        return () => {
            window.removeEventListener('keydown', handleDown);
            window.removeEventListener('keyup', handleUp);
            window.removeEventListener('click', handleClick);
            document.removeEventListener('mousemove', handleMouseMove);
        };
    }, [anno,param,activeEdge,altKey]);



    useEffect(() => {

        let annotorious = null;
        if (imgEl.current) {
                // Init
                annotorious = new Annotorious({
                    image: imgEl.current,
                    locale: 'auto',
                    fragmentUnit:"percent",
                    disableEditor: true,
                    formatter: formatter
                });

                annotorious.on('createSelection', async function(selection) {
                    let category = localStorage.getItem("category")
                    if (category){
                        selection.body = [{
                            type: 'TextualBody',
                            purpose: 'category',
                            value: category
                        },
                            {
                            type: 'TextualBody',
                            purpose: 'meta',
                            value: ""
                        }];
                    }
                    await annotorious.updateSelected(selection);
                    annotorious.saveSelected();

                });

                annotorious.on('selectAnnotation', async function(annotation, element) {
                    setMeta("")
                    localStorage.removeItem("anno_meta")
                    let dr_tool = localStorage.getItem("drawing_tool")
                    if (dr_tool === "delete"){
                        annotorious.removeAnnotation(annotation)
                        deleteFromAnnoList(annotation.id)
                    } else {
                        let category = localStorage.getItem("category")
                        let meta_data = annotation.body.filter(prop => prop.purpose === "meta")
                        if (category){
                            if (category !== annotation.body.filter(prop => prop.purpose === "category")[0].value){
                                annotation.body = annotation.body.map(body => {
                                    if (body.purpose === 'category'){
                                        body.value = category
                                    }
                                    return body
                                })
                                await annotorious.updateSelected(annotation);
                                annotorious.saveSelected();
                            } else {
                                if (meta_data.length > 0){
                                    localStorage.setItem("anno_meta",meta_data[0].value)
                                    setMeta(meta_data[0].value)
                                }
                            }
                        } else {
                            if (meta_data.length > 0){
                                localStorage.setItem("anno_meta",meta_data[0].value)
                                setMeta(meta_data[0].value)
                            }
                        }

                    }
                    localStorage.setItem("selected_anno",JSON.stringify(annotation))
                    localStorage.setItem("current_anno",JSON.stringify(annotation))
                });

                annotorious.on('cancelSelected', async function(selection) {
                    localStorage.setItem("selected_anno",JSON.stringify(selection))
                    let meta_data = localStorage.getItem("anno_meta")
                    if (meta_data){
                        selection.body = selection.body.map(body => {
                            if (body.purpose === 'meta'){
                                body.value = meta_data
                            }
                            return body
                        })
                        await annotorious.updateSelected(selection);
                        annotorious.saveSelected();
                    }
                    localStorage.removeItem("current_anno")
                });

                // Attach event handlers here
                annotorious.on('createAnnotation', annotation => {
                    addToAnnoList(annotation.id)
                    annotorious.selectAnnotation(annotation);
                });

                annotorious.on('changeSelectionTarget', function(target) {
                    let selected = JSON.parse(JSON.stringify(annotorious.getSelected()))
                    selected.target.selector.value = target.selector.value;
                    localStorage.setItem("current_anno",JSON.stringify(selected))
                });
                setAnno(annotorious);

                // Cleanup: destroy current instance
                return () => annotorious.destroy();

        }
    }, [reload]);


    // Toggles current tool + button label
    const toggleTool = (type) => {
        anno.saveSelected()
        if(type === "delete" || type === "wand"){
            setTool(type);
            localStorage.setItem("drawing_tool",type)
            localStorage.removeItem("category")
            setActiveCategory("")
        }
        else{
            setTool(type);
            anno.setDrawingTool(type);
            localStorage.setItem("drawing_tool",type)
        }

        reloadAnnoComponent()

    }

    //переключение категорий разметки
    const selectCategory = (category)=> {
        anno.saveSelected()
        let drawing_tool = localStorage.getItem("drawing_tool")
        if (drawing_tool === "delete" || drawing_tool === "wand"){
            setTool("rect")
            anno.setDrawingTool("rect");
            localStorage.setItem("drawing_tool","rect")
        }

        setActiveCategory(category)
        localStorage.setItem("category",category)

        reloadAnnoComponent()
    }

    //переключение активного файла разметки
    function handleActiveFile(file){
        reloadAnnoComponent()

        if(file !== currentFile){
            anno.clearAnnotations();
        }

        dispatch(setCurrentFileAction(file))

    }

    function handleBack(){
        navigate("/my_room_admin");
    }

    function handleDelete(){
        dispatch(showDeleteModalAction())
    }

    //сохранение разметки для файла
    async function hadnleConfirm(){

        let selected = anno.getSelected()
        let anno_meta = localStorage.getItem("anno_meta")

        let annotations = anno.getAnnotations().map(an => {
            let markup ={}
            markup._id = an.id
            markup.type = an.target.selector.type
            markup.value = an.target.selector.value

            if (an.body.filter(prop => prop.purpose === 'category').length > 0){
                markup.category = {id : an.body.filter(prop => prop.purpose === 'category')[0].value}
            }
            if (an.body.filter(prop => prop.purpose === 'meta').length > 0){
                markup.meta = an.body.filter(prop => prop.purpose === 'meta')[0].value
                if (selected && anno_meta){
                    if (selected.id === an.id){
                        markup.meta = anno_meta
                    }
                }
            }

            return markup
        })

        reloadAnnoComponent()

        anno.clearAnnotations();


        await dispatch(addMarkupAction(currentDataset._id,currentFolder.info.name,currentFile,annotations))
        await dispatch(setCurrentFileAnnotationsAction(""))
        let new_files = Array.from(files)
        let index = new_files.findIndex(el => el.name === currentFile);

        if(index !== files.length-1){
            await dispatch(setCurrentFileAction(files[index+1].name))
        }else {
            await dispatch(getFileMurkupsAction(currentFile))
        }
        await dispatch(getFilesAction(currentDataset._id,currentFolder.info.name))
    }

    //перехват клика по области разметки
    async function clickListener(e){

        if(e.target.classList.contains("a9s-annotationlayer")){
            //функция волшебной палочки
            if (tool === "wand" && param && accuracy){
                let ev = e.target;
                let dim = ev.getBoundingClientRect();
                let x = e.clientX - dim.left;
                let y = e.clientY - dim.top;
                let data = {
                    "x" : Math.round(Number(x)),
                    "y" : Math.round(Number(y)),
                    "sensitiv" : Number(accuracy),
                    "rounding": Number(param)
                }

                const selected = JSON.parse(localStorage.getItem("selected_anno"))


                let action = "add"

                //объединение выделенной области с новой при нажатом shift
                if (shiftKey && selected){
                    data.prev_selection = selected.target.selector.value
                    anno.removeAnnotation(selected)
                    deleteFromAnnoList(selected.id)
                    action = "concat"
                }

                if (!selected || shiftKey){
                    let resp = await getMagicWandAction(currentDataset._id,currentFolder.info.name,currentFile,data)
                    if (resp){
                        if (resp.data.length > 0){
                            const wand = resp.data[0]
                            const an = {
                                "@context": "http://www.w3.org/ns/anno.jsonld",
                                "id": wand._id,
                                "type": "Annotation",
                                "body": [ {
                                    "type": "TextualBody",
                                    "purpose": "category",
                                    "value": "0"
                                },
                                    {
                                        "type": "TextualBody",
                                        "purpose": "meta",
                                        "value": "",
                                    }],
                                "target": {
                                    "selector": {
                                        "type": wand.type,
                                        "conformsTo": "http://www.w3.org/TR/media-frags/",
                                        "value": wand.value
                                    }
                                }
                            }

                            const history = {
                                action : action,
                                annotation : an,
                                prev : action === "concat" ? selected : ""
                            }
                            localStorage.setItem("history",JSON.stringify(history))

                            addToAnnoList(an.id)
                            anno.addAnnotation(an)
                            anno.selectAnnotation(an);
                        }
                    }
                }

            }
        }
        //сохранение метаинформации к разметке при клике на другой объект
        if (anno){
            if (!e.target.classList.contains("meta_control")) {
                //localStorage.removeItem("selected_anno")
                //anno.saveSelected()
                setMeta("")
                setEditMeta(false)
            }
        }
    }

    async function handleMetaChange(e) {
        setMeta(e.target.value)
        localStorage.setItem("anno_meta",e.target.value)
    }

    function handleActiveMetaEdit() {
        if(anno){
            if (anno.getSelected()){
                setEditMeta(true)
            }
        }
    }

    //проверка ввода параметров для магической палочки (числа от 0 до 100)
    function setWandParams(val,prop) {
        let numberRegex = /^\d+$/;
        if(numberRegex.test(val) || val === ""){
            if (Number(val) >= 100){
                val = 100
            }
            if(prop === "accuracy"){
                setAccuracy(val)
            }else {
                setParam(val)
            }
        }
    }

    return ( currentFolder &&
        <div className="anno_content" onClick={(e)=>clickListener(e)}>
            <div className="adm_mr">
                <div className="adm_mr_btn_group">
                    <button type="button" onClick={handleBack} className="adm_mr_btn">
                        <img src={IconBack} alt="Logo"/>
                        <p className="gt_h4 gt_gb">{currentDataset.info.name}</p>
                        <p className="gt_h4 "> / {currentFolder.info.name}</p>
                    </button>
                </div>

                <div className="search">
                    <label htmlFor="search">
                        <img src={IconSearch} alt="search"/>
                    </label>
                    <input placeholder="Поиск по имени файла" id="search" className="gt_pt" value={search} onChange={(e)=>setSearch(e.target.value)} />
                </div>

                <div className="anno_table">
                    <div className="files_block">
                        <div className="files_block_header">
                            <p className="gt_pt"><span className="gt_th">Обработано: </span>{files.filter(file=>file.annotated).length} / {files.length}</p>
                        </div>
                        <div className="files_block_list">
                            {files.filter(file => file.name.toLowerCase().includes(search.toLowerCase())).map(file => (
                                <button type="button" className={`gt_flex_row files_block_item ${file.name === currentFile ? "active" : ""}`} key={file.name} onClick={()=>handleActiveFile(file.name)}>
                                    <img src={file.annotated ? IconFileMark : IconFile} alt="file"/><p className="gt_pt files_block_item_text">{file.name}</p>
                                    <div className="mod_circles">
                                    {file.modifiers.slice(-1).map((mod) =>
                                            <div className="name_circle" key={file.name+mod}>
                                                <p className="gt_ps">{mod}</p>
                                            </div>
                                    )}
                                    </div>

                                </button>
                            ))}
                        </div>
                    </div>
                    <div className="annotation_main">
                        <div className="annotation_block">

                            {fileUrl.length > 0 ? <img
                                    ref={imgEl}
                                    src={URL.createObjectURL(fileUrl[0])}
                                    alt="markup"
                                    className="anno_image"
                                    id="main"
                                /> :
                                <img
                                    ref={imgEl}
                                    src={IconWait}
                                    alt="markup"
                                id="empty"/>}


                            <div className="tool_box">
                                <div className={`search meta_control ${!editMeta ? "meta_disabled" : ""}`} >
                                    <label htmlFor="meta-info">
                                        <button type="button" className="meta_control">
                                            <img src={editMeta ? IconEdit : IconEditDisable} alt="meta-info" className="meta_control" onClick={handleActiveMetaEdit}/>
                                        </button>
                                    </label>
                                    <input id="meta-info" className="gt_pt meta_control" value={meta} onChange={(e)=>handleMetaChange(e)} disabled={!editMeta}/>
                                </div>

                                {tool === "wand" &&
                                    <div className="wand_params">
                                        <div className="input_group">
                                            <label className="gt_ih" htmlFor="accuracy">
                                                Охват
                                            </label>
                                            <input id="accuracy" className="ds_create_form_input gt_pt shadow"
                                                   value={accuracy}
                                                   onChange={(e) => setWandParams(e.target.value,"accuracy")}/>
                                        </div>
                                        <div className="input_group">
                                            <label className="gt_ih" htmlFor="param">
                                                Огрубление
                                            </label>
                                            <input id="param" className="ds_create_form_input gt_pt shadow"
                                                   value={param}
                                                   onChange={(e) => setWandParams(e.target.value,"param")}/>
                                        </div>
                                    </div>
                                }
                            </div>



                            <div className="class_btn_block">
                                {currentDataset.info.categories.map((cat, i) =>
                                    <button type="button" onClick={() => selectCategory(cat.id)} key={cat.id}
                                            className={`cat_button ${cat.id === activeCategory ? "active" : ""}`}>
                                        <div className="class_element">
                                        <div className="main_color " style={{backgroundColor: cat.color}}/>
                                            <div className="gt_pt op_color" style={{backgroundColor: COLORS.filter(color => color.color === cat.color)[0].opacity}}>
                                                <p>{cat.name} <span className="gt_th">{i+1}</span></p>
                                            </div>
                                        </div>
                                    </button>
                                )}
                            </div>

                            <div className="confirm_btn">
                                <button type="button" className="gt_btn_primary" onClick={hadnleConfirm}>Подтвердить</button>
                                <button type="button" className="gt_btn_primary del_btn" onClick={handleDelete}>Удалить</button>

                            </div>
                        </div>
                        <div className="annotation_btns">
                            <button type="button" className={`drawing_tool ${tool === "rect" ? "active" : ""}`}
                                    onClick={() => toggleTool("rect")}>
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                          d="M14.5455 3.125H1.45455C1.25371 3.125 1.09091 3.29289 1.09091 3.5V12.5C1.09091 12.7071 1.25371 12.875 1.45455 12.875H14.5455C14.7463 12.875 14.9091 12.7071 14.9091 12.5V3.5C14.9091 3.29289 14.7463 3.125 14.5455 3.125ZM1.45455 2C0.651222 2 0 2.67157 0 3.5V12.5C0 13.3284 0.651222 14 1.45455 14H14.5455C15.3488 14 16 13.3284 16 12.5V3.5C16 2.67157 15.3488 2 14.5455 2H1.45455Z"
                                          fill="#0D4CD3"/>
                                </svg>
                            </button>
                            <button type="button" className={`drawing_tool ${tool === "polygon" ? "active" : ""}`}
                                    onClick={() => toggleTool("polygon")}>
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                          d="M13.6974 5.81798L14.7544 12.4067L11.1496 10.9087C10.8036 10.765 10.4246 10.7301 10.0596 10.8086L1.39126 12.6718L3.43717 7.62374C3.67506 7.03679 3.62557 6.36663 3.30429 5.82425L1.84137 3.35456L13.6974 5.81798ZM14.1779 4.71069C14.4873 4.77499 14.7263 5.03137 14.7781 5.35476L15.9892 12.9037C16.0866 13.5106 15.5077 13.9946 14.9579 13.7661L10.7265 12.0078C10.5881 11.9502 10.4364 11.9363 10.2905 11.9677L0.914867 13.9829C0.315869 14.1117 -0.176449 13.4896 0.0609188 12.9039L2.38709 7.16441C2.48225 6.92963 2.46245 6.66156 2.33394 6.44461L0.41415 3.20362C0.0664225 2.61659 0.559423 1.88107 1.21002 2.01625L14.1779 4.71069Z"
                                          fill="#0D4CD3"/>
                                </svg>
                            </button>
                            <button type="button" className={`drawing_tool ${tool === "delete" ? "active" : ""}`}
                                    onClick={() => toggleTool("delete")}>
                                <svg width="15" height="16" viewBox="0 0 15 16" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                          d="M3.74795 2.07275C3.94144 0.880273 5.02361 0 6.2961 0H8.7039C9.97639 0 11.0586 0.880274 11.252 2.07275L11.3484 2.66664H12.652H14.0625L14.0625 2.66667H15V4.00001H13.9531L13.1705 13.5401C13.0566 14.9285 11.8352 16 10.3665 16H4.63349C3.16478 16 1.94335 14.9285 1.82946 13.5401L1.04688 4.00001H0V2.66667H0.937503L0.9375 2.66664H2.348H3.65159L3.74795 2.07275ZM9.92562 2.66664H5.07438L5.13785 2.2755C5.2258 1.73346 5.71769 1.33334 6.2961 1.33334H8.7039C9.28231 1.33334 9.7742 1.73346 9.86215 2.2755L9.92562 2.66664ZM2.45738 4.00001L3.23148 13.4367C3.28842 14.1309 3.89914 14.6667 4.63349 14.6667H10.3665C11.1009 14.6667 11.7116 14.1309 11.7685 13.4367L12.5426 4.00001L2.45738 4.00001Z"
                                          fill="#0D4CD3"/>
                                </svg>
                            </button>

                            <button type="button" className={`drawing_tool ${tool === "wand" ? "active" : ""}`}
                                    onClick={() => toggleTool("wand")}>
                                <svg fill="#0D4CD3" width="64px" height="64px" viewBox="0 0 32 32">
                                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                    <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                    <g id="SVGRepo_iconCarrier">
                                        <path
                                            d="M31.891 13.418l-3.212-4.802 1.599-5.588c0.099-0.35 0.002-0.728-0.257-0.985-0.258-0.258-0.633-0.353-0.986-0.251l-5.578 1.629-4.822-3.247c-0.303-0.204-0.692-0.229-1.014-0.061-0.324 0.166-0.532 0.496-0.544 0.859l-0.173 5.811-4.578 3.581c-0.287 0.225-0.428 0.588-0.371 0.947s0.306 0.659 0.65 0.782l4.296 1.54c-0.029 0.023-0.059 0.044-0.087 0.071l-16.586 16.586c-0.391 0.39-0.391 1.023 0 1.414 0.196 0.195 0.451 0.293 0.707 0.293s0.511-0.098 0.707-0.293l16.586-16.586c0.064-0.065 0.114-0.137 0.157-0.213l1.681 4.611c0.125 0.342 0.426 0.589 0.786 0.645 0.051 0.008 0.102 0.012 0.154 0.012 0.306 0 0.599-0.142 0.791-0.389l3.555-4.599 5.747-0.205c0.364-0.012 0.692-0.223 0.858-0.548s0.139-0.714-0.066-1.015zM24.814 13.205c-0.297 0.010-0.574 0.153-0.756 0.388l-2.733 3.535-1.53-4.197c-0.102-0.28-0.323-0.498-0.602-0.598l-4.206-1.508 3.52-2.752c0.235-0.184 0.374-0.461 0.384-0.758l0.133-4.467 3.706 2.496c0.247 0.166 0.555 0.213 0.839 0.131l4.289-1.253-1.23 4.296c-0.081 0.286-0.032 0.593 0.136 0.839l2.517 3.691z">
                                        </path>
                                    </g>
                                </svg>
                            </button>

                        </div>
                    </div>

                </div>

            </div>
            {showDeleteModal && <DeleteModal/>}
        </div>
    );
}

export default Annotation;
