import {useEffect, useRef, useState} from 'react';
import {COLORS} from "../../utils/color_for_classes"
import { Recogito } from '@recogito/recogito-js';
import '@recogito/recogito-js/dist/recogito.min.css';
import "../Annotation/Annotation.scss"
import "./TextAnnotationComponen.scss"
import "../MyRoom/Administrator/MyRoom/AdminDatasetsComponent/AdminDatasetsComponent.scss"
import '@recogito/annotorious/dist/annotorious.min.css';
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import IconBack from "../../assets/icons/icon-arrow-left.svg";
import IconSearch from "../../assets/icons/icon_search.svg";
import IconFile from "../../assets/icons/file_icon.svg";
import IconFileMark from "../../assets/icons/file_marked.svg";
import {
    setCurrentFileAction,
    setCurrentFileAnnotationsAction,
} from "../../store/reducers/folderReducer";

import {
    addMarkupAction,
    downloadFilesAction,
    getFileMurkupsAction,
    getFilesAction
} from "../../store/actions/FolderAction";
import {showDeleteModalAction} from "../../store/reducers/appReducer";
import DeleteModal from "../DeleteModal/DeleteModal";
import uniqid from "uniqid";
import CheckboxGosteh from "../CheckboxGosteh/CheckboxGosteh";

function Annotation() {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const {showDeleteModal} = useSelector(store=>store.app)
    const {currentFolder,files,fileUrl,currentFile,currentFileAnnotations} = useSelector(store=>store.folder)
    const {currentDataset} = useSelector(store=>store.dataset)
    const [search,setSearch] = useState("")
    const [activeCategory,setActiveCategory] = useState("")
    const [summary, setSummary] = useState("")
    // Ref to the image DOM element
    const [text,setText] = useState("");
    // The current Annotorious instance
    const [ anno, setAnno ] = useState();
    const contentEl = useRef();

    const [font,setFont] = useState(100);
    // Current drawing tool name
    const [ tool, setTool ] = useState('');

    const [autofill,setAutofill] = useState(true);

    let formatter = function(annotation) {
        if (currentDataset){
            if(annotation.bodies.filter(prop => prop.purpose === "category").length > 0){
                let category = annotation.bodies.filter(prop => prop.purpose === "category")[0].value
                if(currentDataset.info.categories.filter(cat => cat.id === category).length > 0){
                    let color = currentDataset.info.categories.filter(cat => cat.id === category)[0].color
                    let opacity = COLORS.filter(col => col.color === color)[0].opacity

                    let style = "background-color: " + opacity;

                    return {
                        'style': style
                    }
                }

            }
        }
    }


    useEffect(()=>{
        if (fileUrl.length > 0){
            let reader = new FileReader();
            reader.onload = function() {
                setText(reader.result)
            }
            reader.readAsText(fileUrl[0]);
        }
    },[fileUrl])

    useEffect(()=>{
        if (currentFile && anno){
            localStorage.removeItem("category")
            localStorage.removeItem("drawing_tool")
            setTool('ANNOTATION')
            anno.setMode('ANNOTATION')
            setActiveCategory("")
            setSummary("")
            anno.clearAnnotations();

            dispatch(downloadFilesAction(currentDataset._id, currentFolder.info.name, currentFile))
            dispatch(getFileMurkupsAction(currentFile))
        }
    },[currentFile,anno])


    useEffect(()=>{
        if (currentFileAnnotations && anno){
                let annotate = [];

                if (currentFileAnnotations.length > 0){
                    if (currentFile === currentFileAnnotations[0].filename){
                        currentFileAnnotations.forEach(an => {
                            if (an.type === "TextPositionSelector"){
                                annotate.push({
                                    "@context": "http://www.w3.org/ns/anno.jsonld",
                                    "id": an._id,
                                    "type": "Annotation",
                                    "body": [ {
                                        "type": "TextualBody",
                                        "purpose": "category",
                                        "value": an.category.id
                                    }],
                                    "target": {
                                        "selector": [{
                                            "type": "TextPositionSelector",
                                            "start": Number(an.value[0]),
                                            "end": Number(an.value[1]),
                                        }]
                                    }
                                })
                            } else if (an.type === "linking"){
                                annotate.push({
                                    "@context": "http://www.w3.org/ns/anno.jsonld",
                                    "id": an._id,
                                    "type": "Annotation",
                                    "motivation": "linking",
                                    "body": [ {
                                        "type": "TextualBody",
                                        "purpose": "tagging",
                                        "value": an.value[2]
                                    }],
                                    "target": [
                                        {"id" : an.value[0]},
                                        {"id" : an.value[1]}
                                    ]
                                })
                            } else if (an.type === "TextSummary"){
                                setSummary(an.meta)
                            }

                        })
                    }
                }

                if (annotate.length > 0){
                    anno.setAnnotations(annotate);
                }
            }
    },[currentFileAnnotations,anno])


    useEffect(() => {

        localStorage.setItem("autofill","true")

        if (contentEl.current){
            let annotorious = null;
            // Init
            annotorious = new Recogito({
                content: contentEl.current,
                disableEditor: true,
                formatter: formatter
            });

            annotorious.on('selectAnnotation', async function(annotation, element) {

                let dr_tool = localStorage.getItem("drawing_tool")

                if (dr_tool === "delete"){
                    let current_annotations = annotorious.getAnnotations()

                    let del_id = current_annotations.filter(an => an.motivation === "linking" && an.target.filter(target => target.id === annotation.id).length > 0).map(an => an.id)

                    current_annotations = current_annotations.filter(an => an.id !== annotation.id && !del_id.includes(an.id))
                    annotorious.clearAnnotations();
                    annotorious.setAnnotations(current_annotations);
                } else if (dr_tool === "ANNOTATION" || !dr_tool) {
                    let category = localStorage.getItem("category")
                    if (category){
                        if (category !== annotation.body.filter(prop => prop.purpose === "category")[0].value){

                            annotorious.removeAnnotation(annotation)
                            annotation.body = annotation.body.map(body => {
                                if (body.purpose === 'category'){
                                    body.value = category
                                }
                                return body
                            })
                            annotorious.addAnnotation(annotation)
                        }
                    }
                }
            });

            annotorious.on('cancelSelected', async function(selection) {

            });

            // Attach event handlers here
            annotorious.on('createAnnotation', async function(annotation)  {
                annotorious.removeAnnotation(annotation)

                let category = localStorage.getItem("category")
                if (category){
                    annotation.body = [{
                        type: 'TextualBody',
                        purpose: 'category',
                        value: category
                    }]

                    let autoF = localStorage.getItem("autofill")
                    if (autoF === "true") {

                        let text_content = document.getElementById('text-content').textContent
                        function getListIdx(str, substr) {
                            let listIdx = []
                            let lastIndex = -1
                            while ((lastIndex = str.indexOf(substr, lastIndex + 1)) !== -1) {
                                listIdx.push(Number(lastIndex))
                            }
                            return listIdx
                        }
                        let arr = getListIdx(text_content, " ")
                        arr = arr.concat(getListIdx(text_content, "\n")).sort(function(a, b){return a - b})

                        let start = 0

                        arr.forEach(item => {
                            if (item <= annotation.target.selector[1].start) {
                                start = item+1
                            }
                        })


                        let end =  arr.find(item => {
                            if (item >= annotation.target.selector[1].end) {
                                return item
                            }
                        })

                        end = end ? end : text_content.length
                        annotation.target.selector[1].start = start
                        annotation.target.selector[1].end = end
                    }

                    annotorious.addAnnotation(annotation)
                }

            });

            annotorious.on('updateAnnotation', (annotation, previous) => {

            });

            annotorious.on('deleteAnnotation', annotation => {
            });

            setAnno(annotorious);

            if (files.length === 0 ){
                navigate("/my_room_admin");
            }

            if (files.length > 0){
                dispatch(setCurrentFileAction(files[0].name))
            }
            return () => annotorious.destroy();
        }

    }, []);




    // Toggles current tool + button label
    const toggleTool = (type) => {
        if(type === "delete"){
            setTool(type);
            localStorage.setItem("drawing_tool",type)
            localStorage.removeItem("category")
            setActiveCategory("")
            anno.disableEditor = true
            anno.setMode("ANNOTATION");
        } else{
            if (type === "RELATIONS"){
                anno.disableEditor = false
                localStorage.removeItem("category")
                setActiveCategory("")
            }else {
                anno.disableEditor = true
            }
            anno.setMode(type);
            setTool(type);
            localStorage.setItem("drawing_tool",type)
        }
    }

    const selectCategory = (category)=> {
        let drawing_tool = localStorage.getItem("drawing_tool")
        if (drawing_tool === "delete" || drawing_tool === "RELATIONS"){
            setTool("ANNOTATION")
            anno.setMode("ANNOTATION");
            localStorage.setItem("drawing_tool","ANNOTATION")
            anno.disableEditor = true
        }

        setActiveCategory(category)
        localStorage.setItem("category",category)
    }

    function handleActiveFile(file){
        if(currentFile !== file){
            dispatch(setCurrentFileAction(file))
            dispatch(getFilesAction(currentDataset._id,currentFolder.info.name))
            anno.clearAnnotations();
        }
    }

    function handleBack(){
        navigate("/my_room_admin");
    }

    function handleDelete(){
        dispatch(showDeleteModalAction())
    }

    async function hadnleConfirm(){
        let annotations = anno.getAnnotations().map(an => {
            let markup = {}
            markup._id = an.id
            if(an.motivation === "linking"){
                markup.type = "linking"
                let val = an.target.map(target => target.id.toString())
                val.push(an.body[0].value)
                markup.value = val
                markup.category = {id : "9999999999"}
            } else {
                let pos = an.target.selector.filter(sel => sel.type === "TextPositionSelector")[0]
                markup.value = [pos.start.toString(), pos.end.toString()]
                markup.type = "TextPositionSelector"
                if (an.body.filter(prop => prop.purpose === 'category').length > 0){
                    markup.category = {id : an.body.filter(prop => prop.purpose === 'category')[0].value}
                }
            }

            return markup
        })
        if (summary){
            annotations.push({
                _id : uniqid()+Date.now(),
                type : "TextSummary",
                value : ["0", text.length.toString()],
                category: {
                    id:"99999999999"
                },
                meta : summary
            })
        }


        anno.clearAnnotations();
        setSummary("")

        dispatch(addMarkupAction(currentDataset._id,currentFolder.info.name,currentFile,annotations))
        dispatch(setCurrentFileAnnotationsAction(""))
        let new_files = Array.from(files)
        let index = new_files.findIndex(el => el.name === currentFile);

        if(index !== files.length-1){
            dispatch(setCurrentFileAction(files[index+1].name))
        }else {
            setTimeout(()=>dispatch(getFileMurkupsAction(currentFile)),100)

        }
        dispatch(getFilesAction(currentDataset._id,currentFolder.info.name))
    }

    function handleChangeFont(sig) {

        let current_annotations = anno.getAnnotations()
        anno.clearAnnotations();
        const step = 10
        let new_font = font
        if (sig === "-"){
            new_font = new_font - step
        }else{
            new_font = new_font + step
        }
        setFont(new_font)
        anno.setAnnotations(current_annotations);
    }

    function changeAutofill(name,val){
        setAutofill(val)
        localStorage.setItem("autofill",val)
    }
    return ( currentFolder &&
        <div className="anno_content">
            <div className="adm_mr">
                <div className="adm_mr_btn_group">
                    <button type="button" onClick={handleBack} className="adm_mr_btn">
                        <img src={IconBack} alt="Logo"/>
                        <p className="gt_h4 gt_gb">{currentDataset.info.name}</p>
                        <p className="gt_h4 "> / {currentFolder.info.name}</p>
                    </button>
                </div>

                <div className="search">
                    <label htmlFor="search">
                        <img src={IconSearch} alt="search"/>
                    </label>
                    <input placeholder="Поиск по имени файла" id="search" className="gt_pt" value={search} onChange={(e)=>setSearch(e.target.value)} />
                </div>

                <div className="anno_table">
                    <div className="files_block">
                        <div className="files_block_header">
                            <p className="gt_pt"><span className="gt_th">Обработано: </span>{files.filter(file=>file.annotated).length} / {files.length}</p>
                        </div>
                        <div className="files_block_list">
                            {files.filter(file => file.name.toLowerCase().includes(search.toLowerCase())).map(file => (
                                <button type="button" className={`gt_flex_row files_block_item ${file.name === currentFile ? "active" : ""}`} key={file.name} onClick={()=>handleActiveFile(file.name)}>
                                    <img src={file.annotated ? IconFileMark : IconFile} alt="file"/><p className="gt_pt files_block_item_text">{file.name}</p>
                                    <div className="mod_circles">
                                        {file.modifiers.slice(-1).map((mod) =>
                                            <div className="name_circle" key={file.name+mod}>
                                                <p className="gt_ps">{mod}</p>
                                            </div>
                                        )}
                                    </div>

                                </button>
                            ))}
                        </div>
                    </div>
                    <div className="annotation_main">
                        <div className="annotation_block">
                            <div id="text-content" className="gt_pt" style={{fontSize:(14*font/100)+"px"}} ref={contentEl} >
                                {text}
                                <svg className="r6o-relations-layer readonly"></svg>
                            </div>

                            <div className="class_btn_block">
                                {currentDataset.info.categories.map((cat, i) =>
                                    <button type="button" onClick={() => selectCategory(cat.id)} key={cat.id}
                                            className={`cat_button ${cat.id === activeCategory ? "active" : ""}`}>
                                        <div className="class_element">
                                            <div className="main_color " style={{backgroundColor: cat.color}}/>
                                            <div className="gt_pt op_color"
                                                 style={{backgroundColor: COLORS.filter(color => color.color === cat.color)[0].opacity}}>
                                                <p>{cat.name} <span className="gt_th">{i + 1}</span></p>
                                            </div>
                                        </div>
                                    </button>
                                )}
                            </div>
                            <div className="summary_block">
                                <label className="gt_ih" htmlFor="summary">
                                    Информация о тексте
                                </label>
                                <textarea id="summary" rows={3} className="summary_edit gt_pt shadow"
                                          value={summary} onChange={(e) => setSummary(e.target.value)}/>
                            </div>

                            <div className="text_tools">

                                <CheckboxGosteh
                                    key="autofill"
                                    label="Автовыделение"
                                    check_value={autofill}
                                    onChange={changeAutofill}
                                    name="autofill"
                                />

                                <div className="size_change">
                                    <p className="gt_pt">Размер шрифта </p>
                                    <button type="button" className="gt_btn_secondary"
                                            onClick={() => handleChangeFont("-")}>-
                                    </button>
                                    {font}%
                                    <button type="button" className="gt_btn_secondary"
                                            onClick={() => handleChangeFont("+")}>+</button>
                                </div>


                            </div>


                            <div className="confirm_btn">
                                <button type="button" className="gt_btn_primary" onClick={hadnleConfirm}>Подтвердить
                                </button>
                                <button type="button" className="gt_btn_primary del_btn"
                                        onClick={handleDelete}>Удалить
                                </button>

                            </div>
                        </div>
                        <div className="annotation_btns">
                            <button type="button" className={`drawing_tool ${tool === "ANNOTATION" ? "active" : ""}`}
                                    onClick={() => toggleTool('ANNOTATION')}>
                            <svg fill="#0D4CD3" width="16" height="16" viewBox="0 0 1024 1024">
                                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                    <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                    <g id="SVGRepo_iconCarrier">
                                        <path
                                            d="M957.6 507.4L603.2 158.2a7.9 7.9 0 0 0-11.2 0L353.3 393.4a8.03 8.03 0 0 0-.1 11.3l.1.1 40 39.4-117.2 115.3a8.03 8.03 0 0 0-.1 11.3l.1.1 39.5 38.9-189.1 187H72.1c-4.4 0-8.1 3.6-8.1 8V860c0 4.4 3.6 8 8 8h344.9c2.1 0 4.1-.8 5.6-2.3l76.1-75.6 40.4 39.8a7.9 7.9 0 0 0 11.2 0l117.1-115.6 40.1 39.5a7.9 7.9 0 0 0 11.2 0l238.7-235.2c3.4-3 3.4-8 .3-11.2zM389.8 796.2H229.6l134.4-133 80.1 78.9-54.3 54.1zm154.8-62.1L373.2 565.2l68.6-67.6 171.4 168.9-68.6 67.6zM713.1 658L450.3 399.1 597.6 254l262.8 259-147.3 145z"></path>
                                    </g>
                                </svg>
                            </button>
                            <button type="button" className={`drawing_tool ${tool === "RELATIONS" ? "active" : ""}`}
                                    onClick={() => toggleTool('RELATIONS')}>
                                <svg viewBox="0 0 24 24" fill="#0D4CD3" >
                                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                    <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                    <g id="SVGRepo_iconCarrier">
                                        <path
                                            d="M5.65681 7.75728C5.26629 7.36675 4.63312 7.36675 4.2426 7.75728L1.41426 10.5857C0.633215 11.3667 0.633121 12.6331 1.41417 13.4141L4.24269 16.2425C4.63321 16.633 5.26638 16.633 5.6569 16.2425C6.04743 15.852 6.04743 15.2188 5.6569 14.8283L3.82849 12.9999L17.9998 12.9999L18.0015 12.9999H20.1714L18.3429 14.8283C17.9524 15.2188 17.9524 15.852 18.3429 16.2425C18.7335 16.633 19.3666 16.633 19.7572 16.2425L22.5856 13.4141C23.3666 12.633 23.3668 11.3667 22.5857 10.5857L19.7573 7.75725C19.3668 7.36672 18.7336 7.36672 18.3431 7.75725C17.9526 8.14777 17.9526 8.78094 18.3431 9.17146L20.1715 10.9999L3.82842 10.9999L5.65681 9.17149C6.04734 8.78097 6.04734 8.1478 5.65681 7.75728Z"
                                            ></path>
                                    </g>
                                </svg>
                            </button>
                            <button type="button" className={`drawing_tool ${tool === "delete" ? "active" : ""}`}
                                    onClick={() => toggleTool("delete")}>
                                <svg width="15" height="16" viewBox="0 0 15 16" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                          d="M3.74795 2.07275C3.94144 0.880273 5.02361 0 6.2961 0H8.7039C9.97639 0 11.0586 0.880274 11.252 2.07275L11.3484 2.66664H12.652H14.0625L14.0625 2.66667H15V4.00001H13.9531L13.1705 13.5401C13.0566 14.9285 11.8352 16 10.3665 16H4.63349C3.16478 16 1.94335 14.9285 1.82946 13.5401L1.04688 4.00001H0V2.66667H0.937503L0.9375 2.66664H2.348H3.65159L3.74795 2.07275ZM9.92562 2.66664H5.07438L5.13785 2.2755C5.2258 1.73346 5.71769 1.33334 6.2961 1.33334H8.7039C9.28231 1.33334 9.7742 1.73346 9.86215 2.2755L9.92562 2.66664ZM2.45738 4.00001L3.23148 13.4367C3.28842 14.1309 3.89914 14.6667 4.63349 14.6667H10.3665C11.1009 14.6667 11.7116 14.1309 11.7685 13.4367L12.5426 4.00001L2.45738 4.00001Z"
                                          fill="#0D4CD3"/>
                                </svg>
                            </button>
                        </div>
                    </div>

                </div>

            </div>
            {showDeleteModal && <DeleteModal/>}
        </div>
    );
}

export default Annotation;
