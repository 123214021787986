export function checkIsPointOnEdge(pointX, pointY, edge) {
    const tolerances = 3; // Acceptable imprecision in calculations

    const [x1, y1] = edge[0];
    const [x2, y2] = edge[1];

    // check if the point is out of range
    const [left, right] = (x1 < x2) ? [x1, x2] : [x2, x1];
    if (pointX < left || right < pointX) {
        return false;
    }

    // 1) check if it is a vertical line
    if ((right - left) < tolerances) {
        const [top, bottom] = (y1 < y2) ?  [y1, y2] : [y2, y1];
        return (bottom > pointY) && (pointY > top) ? edge : false;
    }

    const tngA = (y2 - y1) / (x2 - x1);
    const shift = y1 - x1 * tngA;

    const yPprojection = pointX * tngA + shift;
    if (Math.abs(yPprojection - pointY) > tolerances) {
        return false;
    } else {
        return edge;
    }
}
